// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// init Swiper:
const swiper = new Swiper('.swiper', {
    slidesPerView: 1,
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
});

// toggle navigation
const menuToggle = document.querySelector(".menu-toggle");
const navigation = document.querySelector("#site-navigation");
const navlinks = document.querySelectorAll("#site-navigation a");

const toggleMenu = function() {
    navigation.classList.toggle("open");
    menuToggle.setAttribute(
        'aria-expanded', 
        menuToggle.getAttribute('aria-expanded') === 'true' 
            ? 'false' 
            : 'true'
    );
    header.classList.toggle("dark-theme");
    header.classList.toggle("light-theme");
}

menuToggle.addEventListener("click", function() {
    toggleMenu();
});

navlinks.forEach(link => {
    link.addEventListener("click", function() {
        toggleMenu();
        header.classList.remove("dark-theme");
    })
})

// change header color
const header = document.querySelector("header");
const hero = document.querySelector("#hero");

const scrollHeader = function() {
    const currentScroll = window.pageYOffset;
    const heroHeight = hero.offsetHeight;

    if(currentScroll <= heroHeight) {
        header.classList.remove("light-theme");
    } else {
        header.classList.add("light-theme");
    }
}

window.addEventListener("scroll", function() {
    scrollHeader();
});